import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: {},
    dialog: {},
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
    SET_DIALOG(state, dialog) {
      state.dialog = dialog;
    },
  },
  getters: {},
  actions: {
    setSnackbar({ commit }, snackbar) {
      (snackbar.showing = true),
        (snackbar.color = snackbar.color || "success"),
        (snackbar.timeout = snackbar.tiomeout || 3000);
      if (snackbar.color == "success") {
        snackbar.icon = "mdi-check-circle-outline";
      } else if (snackbar.color == "error") {
        snackbar.icon = "mdi-close-octagon-outline";
      } else if (snackbar.color == "warning") {
        snackbar.icon = "mdi-shield-lock-outline";
      } else if (snackbar.color == "info") {
        snackbar.icon = "mdi-bell-alert-outline";
      }
      commit("SET_SNACKBAR", snackbar);
    },
    setDialog({ commit }, dialog) {
      (dialog.showing = true),
        (dialog.titlecolor = dialog.titlecolor || "success"),
        // (dialog.timeout = dialog.tiomeout || 3000);
        (dialog.timeout = setTimeout(() => {
          dialog.showing = false;
        }, 1500));
      if (dialog.titlecolor == "success") {
        dialog.icon = "mdi-check-circle-outline";
      } else if (dialog.titlecolor == "error") {
        dialog.icon = "mdi-close-octagon-outline";
      } else if (dialog.titlecolor == "warning") {
        dialog.icon = "mdi-shield-lock-outline";
      } else if (dialog.titlecolor == "info") {
        dialog.icon = "mdi-bell-alert-outline";
      }
      commit("SET_DIALOG", dialog);
    },
  },
  modules: {},
});
