import Vue from "vue";
import VueRouter from "vue-router";
import Printcard from "../views/Printcard.vue";
// import Test from "../views/Test.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: "Test",
    // component: Test,
    name: "Printcard",
    component: Printcard,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
