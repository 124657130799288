<template>
  <v-container fluid>
    <v-row>
      <v-col lg="6" md="6" sm="12" cols="12">
        <v-stage ref="stage" :config="stageSize">
          <v-layer ref="layer">
            <v-group ref="group">
              <url-image
                :config="{
                  src: src,
                  width: 210,
                  height: 255,
                  cornerRadius: 105,
                }"
              ></url-image>
              <v-image :config="configImg2" ref="avatar" />
              <v-image :config="configImg" />
              <v-image
                :config="{
                  image: imageback,
                  x: 350,
                  y: 5,
                  scaleX: 0.51,
                  scaleY: 0.47,
                  stroke: '#0F6D55',
                  strokeWidth: 10,
                }"
              />
              <v-image :config="configCircle"></v-image>
              <v-text
                :config="{
                  text: name,
                  x: 60,
                  y: 340,
                  width: 220,
                  fontSize: 20,
                  fontFamily: 'Kanit',
                  fontStyle: 'bold',
                  fill: '#0F6D55',
                  align: 'center',
                }"
              />
              <v-text
                :config="{
                  text: nameEn,
                  x: 60,
                  y: 365,
                  width: 220,
                  fontSize: 18,
                  fontFamily: 'Oswald',
                  fill: '#0F6D55',
                  align: 'center',
                }"
              />

              <v-text
                :config="{
                  text: code,
                  x: 170,
                  y: 414,
                  fontSize: 14,
                  fontFamily: 'Kanit',
                  fill: '#0F6D55',
                  verticalAlign: 'middle',
                }"
              />
              <v-text
                :config="{
                  text: department,
                  x: 170,
                  y: 433,
                  fontSize: 14,
                  fontFamily: 'Kanit',
                  fill: '#0F6D55',
                  verticalAlign: 'middle',
                }"
              />
              <v-text
                :config="{
                  text: office,
                  x: 170,
                  y: 450,
                  fontSize: 14,
                  fontFamily: 'Kanit',
                  fill: '#0F6D55',
                  verticalAlign: 'middle',
                }"
              />
              <v-text
                :config="{
                  text: datetitle,
                  x: 425,
                  y: 250,
                  fontSize: 16,
                  fontFamily: 'Kanit',
                  fontStyle: 'bold',
                  fill: '#0F6D55',
                  verticalAlign: 'middle',
                }"
              />
              <v-text
                :config="{
                  text: datecreate,
                  x: 520,
                  y: 250,
                  fontSize: 15,
                  fontFamily: 'Oswald',
                  fontStyle: 'bold',
                  fill: '#0F6D55',
                  verticalAlign: 'middle',
                }"
              />
            </v-group>
          </v-layer>
        </v-stage>
      </v-col>
      <v-col lg="3" md="3" sm="12" cols="12">
        <v-card>
          <v-toolbar color="#0F6D55" dark flat>
            <v-toolbar-title> รายละเอียดพนักงาน </v-toolbar-title>
          </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
            class="px-5"
          >
            <v-row align="center" justify="center">
              <v-col lg="10" md="10" sm="12" cols="12">
                <v-radio-group
                  v-model="radioGroup"
                  @change="changeBackground()"
                >
                  <v-radio label="บริษัท ซีเอฟพี จำกัด" value="1"></v-radio>
                  <v-radio
                    label="บริษัท ไทยโพลทรีย์กรุ๊ป จำกัด"
                    value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col lg="2" md="2" sm="12" cols="12">
                <v-icon x-large @click="toggleShow">
                  mdi-account-box-outline
                </v-icon>
              </v-col>
            </v-row>

            <!-- <a class="btn" @click="toggleShow">set avatar</a> -->
            <my-upload
              field="img"
              ref="uploader"
              @crop-success="cropSuccess"
              v-model="show"
              :width="350"
              :height="370"
              :headers="headers"
              :noSquare="true"
              :noRotate="true"
              img-format="png"
              langType="en"
            ></my-upload>
            <!-- <img :src="imgDataUrl" width="120" /> -->
            <v-text-field
              v-model="name"
              label="ชื่อ-นามสกุล ภาษาไทย"
              :rules="textRule"
              dense
              outlined
              clearable
              required
            ></v-text-field>

            <v-text-field
              v-model="nameEn"
              label="ชื่อ-นามสกุล ภาษาอังกฤษ"
              :rules="textRule"
              dense
              outlined
              clearable
              required
            ></v-text-field>

            <v-text-field
              v-model="code"
              label="รหัสพนักงาน"
              :rules="textRule"
              dense
              outlined
              clearable
              required
            ></v-text-field>

            <v-text-field
              v-model="department"
              :rules="textRule"
              label="ตำแหน่ง"
              dense
              outlined
              clearable
              required
            ></v-text-field>

            <v-text-field
              v-model="office"
              :rules="textRule"
              label="แผนก"
              dense
              outlined
              clearable
              required
            ></v-text-field>

            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <v-btn class="px-10" dark color="btnSubmit" @click="submit()">
                บันทึก
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col lg="3" md="3" sm="12" cols="12">
        <v-card>
          <v-toolbar color="#0F6D55" dark flat>
            <v-toolbar-title> รายการการพิมพ์บัตร </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small fab outlined color="btnCancel" @click="reset()">
              คืนค่า
            </v-btn>
          </v-toolbar>
          <v-simple-table fixed-header height="428px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">รายการพิมพ์บัตร</th>
                  <th class="text-left">ชื่อ-สกุล</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dataAvatar" :key="index">
                  <td>
                    <v-avatar>
                      <img :src="item.avatar" />
                    </v-avatar>
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-icon @click="removeCard(index)">
                      mdi-delete-empty-outline
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn class="px-10" dark color="btnSubmit" @click="pdfPrint()">
              PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <PDF
      @cancelled="onCancelled"
      :pdfViewer="pdfViewer"
      :visible="dialog"
      :title="titlePDF"
    ></PDF>
  </v-container>
</template>

<script>
// const width = 680;
// const height = 563;
const width = 684;
const height = 563;
import moment from "moment";
// import jsPDF from "jspdf";
import UrlImage from "./UrlImage.vue";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import PDF from "@/components/PDF/Pdfviewer";
import { printcardPDF } from "@/PDF/printcard/printcardPDF.js";

export default {
  name: "Printcard",
  components: {
    PDF,
    UrlImage,
    "my-upload": myUpload,
  },
  data() {
    return {
      valid: false,
      show: false,
      imgDataUrl: "",
      headers: {
        smail: "*_~",
      },
      radioGroup: "1",
      src: null,
      stageSize: {
        width: width,
        height: height,
      },
      dataAvatar: [],
      // image: null,
      imagewidth: 0,
      imageback: null,
      name: "",
      nameEn: "",
      code: "",
      department: "",
      office: "",
      datetitle: "วันที่ออกบัตร : ",
      datecreate: null,
      configImg: {
        image: null,
        x: 5,
        y: 5,
        scaleX: 0.51,
        scaleY: 0.47,
        stroke: "#0F6D55",
        strokeWidth: 10,
      },
      configImg2: {
        image: null,
        x: 82,
        y: 160,
        scaleX: 0.5,
        scaleY: 0.5,
        name: "imgAvatar",
      },
      configCircle: {
        x: 167,
        y: 261,
        radius: 87,
        stroke: "#0F6D55",
        strokeWidth: 4,
        image: null,
      },
      dataImg: [],
      dataCard: [],
      textRule: [
        (v) => !!v || "กรุณากรอกข้อมูล",
        (v) => (v && v.length < 50) || "เกิน 50 ตัวอักษร",
      ],
      pdfViewer: "",
      titlePDF: "",
      dialog: false,
    };
  },

  mounted() {
    this.table();
    this.imageShow();
    this.dateCreate();
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.show = false;

      const image = new window.Image();
      image.src = imgDataUrl;

      image.onload = () => {
        this.configImg2.image = image;
      };
    },
    imageShow() {
      const image = new window.Image();
      const imageback = new window.Image();

      image.src = require("../assets/card1_front.png");
      imageback.src = require("../assets/card1_back_new.png");
      image.onload = () => {
        this.configImg.image = image;
      };
      image.crossOrigin = "Anonymous";
      imageback.onload = () => {
        this.imageback = imageback;
      };
      imageback.crossOrigin = "Anonymous";
    },
    changeBackground() {
      let val = this.radioGroup;
      const image = new window.Image();
      const imageback = new window.Image();

      if (val === "1") {
        image.src = require("../assets/card1_front.png");
        imageback.src = require("../assets/card1_back_new.png");
      } else {
        image.src = require("../assets/card2_front.png");
        imageback.src = require("../assets/card2_back.png");
      } //end if
      image.onload = () => {
        this.configImg.image = image;
      };
      image.crossOrigin = "Anonymous";
      imageback.onload = () => {
        this.imageback = imageback;
      };
      imageback.crossOrigin = "Anonymous";
    },
    table() {
      this.dataAvatar = this.dataCard;
    },
    submit() {
      if (this.$refs.form.validate()) {
        let length = this.dataCard.length;
        if (length < 6) {
          const stage = this.$refs.stage.getNode();

          let imgDataurl = stage.toDataURL();

          let data = {
            name: this.name,
            avatar: this.imgDataUrl,
            imgDataUrl: imgDataurl,
          };

          this.dataCard.push(data);
          this.clearCard();
        } else {
          this.$store.dispatch("setDialog", {
            titlecolor: "titleDanger",
            title: "แจ้งเตือน !!!",
            text: "โปรแกรมพิมพ์บัตรได้สูงสุดเพียง 6 รายการเท่านั้น !!!",
            width: 350,
          });
        }
      }
    },
    pdfPrint() {
      this.dialog = true;

      this.pdfViewer = printcardPDF(this.dataCard, "พิมพ์บัตรพนักงาน");
    },
    removeCard(index) {
      this.dataAvatar.splice(index, 1);
    },
    dateCreate() {
      this.datecreate = moment().add("y", 543).format("L");
    },
    clearCard() {
      this.name = "";
      this.nameEn = "";
      this.code = "";
      this.department = "";
      this.office = "";
      this.$refs.uploader.setStep(1);
    },
    onCancelled() {
      this.dialog = false;
    },
    reset() {
      // const stage = this.$refs.stage.getNode();
      // const layer = this.$refs.layer.getNode();
      // const group = this.$refs.group.getNode();

      // const selectedNode = layer.findOne(".imgAvatar");
      // selectedNode.destroy();
      // layer.draw();

      this.dataCard = [];
      this.dataAvatar = [];
      this.$refs.uploader.setStep(1);
    },
  },
};
</script>

<style></style>
