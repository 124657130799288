import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        mainColor: "#14384d",
        titleSuccess: "#1ABC9C",
        titleInfo: "#5499C7",
        titleWarning: "#F8C471",
        titleDanger: "#E74C3C",
        btnCancel: "#E0E0E0",
        btnSubmit: "#0F6D55",
      },
    },
  },
});
