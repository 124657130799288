<template>
  <v-rect
    :config="{
      x: 70,
      y: 160,
      fillPatternImage: image,
      fillPatternScaleX: config.width ? config.width / width : 1,
      fillPatternScaleY: config.height ? config.height / height : 1,
      ...config,
    }"
  ></v-rect>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      image: null,
      width: 1,
      height: 1,
    };
  },
  created() {
    const image = new window.Image();
    image.src = this.config.src;
    image.onload = () => {
      // set image only when it is loaded
      this.image = image;
      this.width = image.width;
      this.height = image.height;
    };
  },
};
</script>
